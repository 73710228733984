@import "~@flaticon/flaticon-uicons/css/all/all";
[data-sal|='slide'] {
    opacity: 1;
    transition-property: opacity, transform;
}
.fb-icon{
    vertical-align: unset !important;
}

.counterup-overlay-top {
    position: relative;
    margin-top: 0;
    padding-top: 0 !important;
}

.main-courses{
    padding-top: 0% !important;
}

.header-acco > button{
    font-size: 20px;
    border: 0 none;
    background: transparent;
    width: 100%;
    text-align: left;
    padding: 13px 35px;
    font-weight: 700;
    line-height: 32px;
    position: relative;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    transition: 0.4s;
}


.course-details-price {
    background-color: #f1f1f1;
    text-align: center;
    padding: 50px;
    margin-bottom: 30px;
    border-bottom: 3px solid;
    border-color: var(--theme-color3);
}

.course-details-price-text {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #81868a;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    line-height: 1em;
}

.course-details-price-amount {
    margin: 0;
    color: var(--theme-color5);
    font-size: 30px;
    font-weight: 600;
    line-height: 1em;
    margin-top: 10px;
}

.btn-style-two {
    position: relative;
    font-size: 12px;
    line-height: 24px;
    padding: 15px 60px;
    margin-top: 20px;
    border: none;
    font-weight: 700;
    letter-spacing: 0.1em;
    overflow: hidden;
    text-transform: uppercase;
    color: #ffffff;
    border-radius: 0px;
    z-index: 1;
    background: #0163B6;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.theme-btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

#COURSE{
    background-color: #F5F5F5;
    padding: 0 30px;
    font-weight: 500;
}

.thumbnail-img{
    max-width: 85px !important;
}

.mb-6{
    margin-bottom: 6rem !important;
}

.certificate-link{
    cursor: pointer;
}

.modal-btn{
    font-size: 14px;
}